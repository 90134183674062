//Since the map will be laoded and displayed on client side
"use client";

import { ENV_CONFIG } from "@/libs/env";
import color from "@/styles/color";
import { Box, Typography } from "@mui/material";
import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import { ReactNode } from "react";

const libraries = ["places", "drawing", "geometry", "maps", "geocoding", "streetView"];

export function MapProvider({
  children,
  fallback,
  noWait
}: {
  children: ReactNode;
  fallback?: JSX.Element;
  noWait?: boolean;
}) {
  const { isLoaded: scriptLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: ENV_CONFIG.MAP_API_KEY,
    libraries: libraries as Libraries,
    version: "weekly",
  });

  if (loadError) return <p>Encountered error while loading google maps</p>;

  if (noWait) return children

  if (!scriptLoaded)
    return fallback ? (
      fallback
    ) : (
      <Box
        component={"div"}
        sx={{
          position: "relative",
          height: { xs: "400px", sm: "360px", xl: "615px" },
          width: "100%",
          boxShadow: "0px 4px 4px 0px #0000001A",
          borderRadius: "16px",
          backgroundColor: color.white,
        }}
      >
        <Typography
          sx={{
            fontWeight: { xs: 600, sm: 700 },
            fontSize: { xs: "18px", sm: "24px", xl: "36px" },
            lineHeight: { xs: "27px", sm: "36px", xl: "54px" },
            textAlign: "center",
            background: color.redGradient,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            paddingTop: { xs: "16px", sm: "unset" },
          }}
        >
          Map Loading...
        </Typography>
      </Box>
    );

  return children;
}
