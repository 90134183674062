export enum IconNameFilePath {
  AccountWhiteIcon = "AccountWhite.svg",
  ArrowCircle = "ArrowCircle",
  ArrowBackIcon = "ArrowBack",
  ArrowDownIcon = "ArrowDown.svg",
  ArrowDownAccordionIcon = "ArrowDownAccordion",
  ArrowDownAccordionGradientIcon = "ArrowDownAccordionGradient",
  ArrowDownGradientIcon = "ArrowDownGradient",
  ArrowDownRoundedRedGradientIcon = "ArrowDownRoundedRedGradientIcon",
  ArrowLeftWhiteIcon = "ArrowLeftWhiteIcon.svg",
  ArrowRightGradientIcon = "ArrowRightGradient",
  ArrowRightWhiteIcon = "ArrowRightWhiteIcon.svg",
  BackwardFillRoundedGrayIcon = "BackwardFillRoundedGray",
  BackwardFillRoundedRedGradientIcon = "BackwardFillRoundedRedGradient",
  BenefitCarGradientIcon = "BenefitCarGradient",
  BinIcon = "Bin.svg",
  BuyCarStep1Icon = "BuyCarStep-1.svg",
  BuyCarStep2Icon = "BuyCarStep-2.svg",
  BuyCarStep3Icon = "BuyCarStep-3.svg",
  BuyCarStep4Icon = "BuyCarStep-4.svg",
  CalculatorGradientIcon = "CalculatorGradient",
  CalendarGradientIcon = "CalendarGradient",
  CalendarOutlineIcon = "CalendarOutline.svg",
  CarIcon = "Car",
  CarDetailClassGradientIcon = "CarDetailClassGradient",
  CarDetailEngineGradientIcon = "CarDetailEngineGradient",
  CarDetailGearGradientIcon = "CarDetailGearGradient",
  CarDetailGradeGradientIcon = "CarDetailGradeGradient",
  CarDetailGradientIcon = "CarDetailGradient",
  CarDetailModelGradientIcon = "CarDetailModelGradient",
  CarDetailSeatGradientIcon = "CarDetailSeatGradient",
  CarDetailSeatTypeGradientIcon = "CarDetailSeatTypeGradient",
  CarDetailTypeGradientIcon = "CarDetailTypeGradient",
  CarFilterCalendarIcon = "CarFilterCalendar",
  CarFilterCarIcon = "CarFilterCar",
  CarFilterCarTagIcon = "CarFilterCarTag",
  CarFilterColorIcon = "CarFilterColor",
  CarFilterCurrencyBahtIcon = "CarFilterCurrencyBaht",
  CarFilterDoorIcon = "CarFilterDoor",
  CarFilterEngineIcon = "CarFilterEngine",
  CarFilterGearIcon = "CarFilterGear",
  CarFilterMapIcon = "CarFilterMap",
  CarFilterMileIcon = "CarFilterMile",
  CarFilterPickupIcon = "CarFilterPickup",
  CarFilterPigMoneyIcon = "CarFilterPigMoney",
  CarFilterPriceTagIcon = "CarFilterPriceTag",
  CarFilterPumpIcon = "CarFilterPump",
  CarFilterToyotaIcon = "CarFilterToyota",
  CarFilterToyotaSureIcon = "CarFilterToyotaSure.svg",
  CarFinanceIcon = "CarFinance.svg",
  CarForIcon = "CarFor",
  CarHatchbackIcon = "CarHatchback",
  CarHistotyIcon = "CarHistoty",
  CarPickupIcon = "CarPickup",
  CarSuvIcon = "CarSuv",
  CarVanIcon = "CarVan",
  CheckCircleIcon = "CheckCircle",
  CheckCircleGreenGradientIcon = "CheckCircleGreenGradient",
  CheckRedGradientIcon = "CheckRedGradient",
  CheckboxCheckedGradientIcon = "CheckboxCheckedGradient",
  CheckedIcon = "Checked",
  CheckedOutlinedIcon = "CheckedOutlined",
  CircleArrowBackIcon = "CircleArrowBack",
  ClockGradientIcon = "ClockGradient",
  CoookieIcon = "Cookie.svg",
  CopyIcon = "Copy.svg",
  CopyrightIcon = "Copyright.svg",
  CorrectGradientIcon = "CorrectGradient",
  DirectLinkGradientIcon = "DirectLinkGradient",
  DistanceLocaltionGradientIcon = "DistanceLocaltionGradient",
  DistanceLocationIcon = "DistanceLocation",
  DownloadGradientIcon = "DownloadGradient",
  EditGradientIcon = "EditGradient.svg",
  EditorIcon = "Editor",
  EditorWhiteIcon = "EditorWhite.svg",
  EmailIcon = "Email.svg",
  EmailGradientIcon = "EmailGradient",
  ErrorCircleShadowIcon = "ErrorCircleShadow.svg",
  ErrorRedGradientIcon = "ErrorRedGradient.svg",
  ExpandIcon = "Expand.svg",
  EyeViewOutlineIcon = "EyeViewOutline.svg",
  FacebookCircleIcon = "FacebookCircle.svg",
  FAQQuestionIcon = "FAQQuestion",
  FilterControlGradientIcon = "FilterControlGradient.svg",
  FindMyCarIcon = "FindMyCar.svg",
  ForwardFillRoundedGrayIcon = "ForwardFillRoundedGray",
  ForwardFillRoundedRedGradientIcon = "ForwardFillRoundedRedGradient",
  ForwardFillRoundedRedGradientSmallIcon = "ForwardFillRoundedRedGradientSmall",
  GearGradientIcon = "GearGradient",
  HearthGradientIcon = "HearthGradient.svg",
  HearthOutlineIcon = "HearthOutline.svg",
  LineCircleIcon = "LineCircle.svg",
  LineTangularIcon = "LineTangular.svg",
  LinkIcon = "Link.svg",
  LocationIcon = "Location.svg",
  LocationGradientIcon = "LocationGradient",
  LockIcon = "Lock.svg",
  MileGradientIcon = "MileGradient",
  ModChipGradientIcon = "ModChipGradient",
  MoneyBagIcon = "MoneyBag.svg",
  MyLocationMarkerIcon = "MyLocationMarker.svg",
  NoticeIcon = "Notice.svg",
  PasswordIcon = "Password.svg",
  PasswordWhiteIcon = "PasswordWhite.svg",
  PencilIcon = "Pencil.svg",
  PinIcon = "Pin.svg",
  PinGoogleIcon = "PinGoogle.svg",
  PriceTagIcon = "PriceTag.svg",
  PrinterIcon = "Printer.svg",
  PromotionTagIcon = "PromotionTag",
  PumpGradientIcon = "PumpGradient",
  SUVIcon = "SUVIcon",
  SearchIcon = "Search.svg",
  SearchBoldIcon = "SearchBoldIcon",
  SearchWhiteIcon = "SearchWhite.svg",
  SedanIcon = "SedanIcon",
  SellingPromotionIcon = "SellingPromotion",
  SellingTagIcon = "SellingTag.svg",
  ShareIcon = "Share.svg",
  ShopDealerIcon = "ShopDealerIcon.svg",
  SignOutIcon = "SignOut.svg",
  SignOutWhiteIcon = "SignOutWhite.svg",
  SocialGradientIcon = "SocialGradient",
  StarIcon = "Star",
  StepCompletedCheckIcon = "StepCompletedCheck.svg",
  SuccessIcon = "Success.svg",
  TagIcon = "Tag",
  TelephoneIcon = "Telephone.svg",
  Telephone2Icon = "Telephone2.svg",
  TelephoneGradientIcon = "TelephoneGradient",
  TruckIcon = "TruckIcon",
  UncheckedIcon = "Unchecked.svg",
  UncheckedRoundIcon = "UncheckedRound.svg",
  UserIcon = "User.svg",
  VanIcon = "VanIcon",
  VehicleIcon = "VehicleIcon",
  VideoIcon = "Video.svg",
  ViewPointHorizontalIcon = "ViewPointHorizontal",
  ViewPointVerticalIcon = "ViewPointVertical",
  XCircleIcon = "XCircle.svg",
  DashedInlineIcon = "DashedInline",
  DashedInlineDesktopIcon = "DashedInlineDesktop",
  LineIcon = "LINE_logo.svg",
  CarProgress = "landing-car.svg",
}

export type IconNameFilePathKey = keyof typeof IconNameFilePath;
