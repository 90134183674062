import { useState, useEffect, FC, useRef } from "react";
import { Box, SvgIcon, SvgIconProps, SxProps, Theme } from "@mui/material";
import { IconNameFilePath, IconNameFilePathKey } from "@/libs/enum/icon-file-path.enum";
import { getRndShortId } from "@/libs/common/get-short-id";

type IconLoaderProps = SvgIconProps & {
  iconName: IconNameFilePathKey;
  sx?: SxProps<Theme>;
};

const IconLoader: FC<IconLoaderProps> = ({ iconName, sx = {}, ...rest }) => {
  const [IconComponent, setIconComponent] = useState<React.ComponentType<{
    name?: string;
  }> | null>(null);
  const isIconLoaded = useRef(false);
  const uniqueId = getRndShortId();

  const extractedFontSize = (sx as any)?.fontSize;
  const iconSize = extractedFontSize || "24px";

  useEffect(() => {
    // if (!isIconLoaded.current) {
    const filePath = IconNameFilePath[iconName];
    import(`@/assets/icons/${filePath}`)
      .then((module) => {
        const LoadedIcon = module.default; // Access the default export
        if (!LoadedIcon) {
          console.error(`Icon "${iconName}" is not a valid component.`);
        }
        if (filePath.endsWith(".svg")) {
          setIconComponent(LoadedIcon);
        }
        if (typeof LoadedIcon === "function") {
          setIconComponent(() => LoadedIcon as React.ComponentType<{ name?: string }>);
        }
      })
      .catch((err) => {
        console.error(`Failed to load icon "${iconName}":`, err);
      });

    isIconLoaded.current = true;
    // }
  }, [iconName]);

  if (!IconComponent) return <Box data-testid="icon-loader-box" style={{ width: iconSize }} />;

  // Render the dynamically loaded icon component, passing the unique ID as a prop
  return (
    <SvgIcon sx={{ ...sx }} {...rest} inheritViewBox data-testid="icon-loader-svg">
      <IconComponent name={uniqueId} data-testid="icon-loader-icon" />
    </SvgIcon>
  );
};

export default IconLoader;
