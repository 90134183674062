"use client";
import color from "@/styles/color";
import { Checkbox, CheckboxProps } from "@mui/material";
import React from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

interface ICheckboxWTBProps extends CheckboxProps {
  iconSize?: string;
}
const label = { inputProps: { "aria-label": "Checkbox filter" } };

function CheckboxWTB({ iconSize = "16px", ...rest }: ICheckboxWTBProps) {
  return (
    <Checkbox
      {...rest}
      {...label}
      sx={{
        width: "20px",
        height: "20px",
        color: color.gray200,
        "&.Mui-checked": {
          background: color.redGradient,
          color: color.white,
          borderRadius: "4px",
        },
        ...rest?.sx,
      }}
      checkedIcon={<CheckRoundedIcon sx={{ fontSize: iconSize }} />}
    />
  );
}

export default CheckboxWTB;
