import color from "@/styles/color";
import { IconButton, IconButtonProps } from "@mui/material";
import React from "react";

interface IIconButtonCustomProps extends IconButtonProps {}

function IconButtonLightWeightCustom({ ...rest }: IIconButtonCustomProps) {
  return (
    <IconButton
      {...rest}
      sx={{
        width: { xs: "30px", sm: "40px", lg: "48px" },
        height: { xs: "30px", sm: "40px", lg: "48px" },
        boxShadow: { xs: "unset", sm: "0px 4px 4px 0px #0000001A" },
        bgcolor: { xs: color.gray240, sm: color.white },
        ":hover": {
          bgcolor: { xs: color.gray240, sm: color.white },
        },
        ...rest?.sx,
      }}
    />
  );
}

export default IconButtonLightWeightCustom;
