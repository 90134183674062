export const _get = (obj: any, path: string, defaultValue: any = undefined) => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const _unique = <T>(input: T[]) => {
  return [...new Set(input)];
};

export const _startCase = (str?: string) => {
  return str
    ?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")
    .replace(/[_\-]+/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const _union = <T>(...arrays: T[][]): T[] => {
  const allValues = arrays.flat();
  return [...new Set(allValues)];
};

export const _range = (start: number, end?: number, step: number = 1): number[] => {
  if (end === undefined) {
    end = start;
    start = 0;
  }

  if (step <= 0) {
    throw new Error("Step must be a positive number.");
  }

  const result: number[] = [];

  for (let i = start; i < end; i += step) {
    result.push(i);
  }

  return result;
};

export const _capitalize = (str: string): string => {
  if (str.length === 0) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};