"use client";
import React from "react";
import "./CarouselCommon.scss";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

interface ICarouselCommonProps {
  children: React.ReactNode[];
}

function CarouselCommon({ children }: ICarouselCommonProps) {
  return (
    <Swiper
      pagination={{
        clickable: true,
        bulletClass: "swiper-pagination-bullet",
        bulletActiveClass: "swiper-pagination-bullet-active",
      }}
      // autoplay={{
      //   delay: 2500,
      //   disableOnInteraction: false,
      // }}
      modules={[Pagination, Autoplay]}
      className="carousel_common_main"
    >
      {children?.map((el, index) => (
        <SwiperSlide key={`carousel-item-${index + 1}`}>{el}</SwiperSlide>
      ))}
    </Swiper>
  );
}

export default CarouselCommon;
