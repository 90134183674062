import dayjs from "dayjs";
import { VehicleCard, VehicleDetail } from "../types/vehicle.type";
import { _startCase } from "./helper";

type VehicleUrlParams = Pick<VehicleCard, "Brand" | "Model" | "VehicleMasterId">;

// export const

export const generateVehicleUrlParams = (vehicleList: VehicleUrlParams[]) => {
  return vehicleList
    .map(({ Brand, Model, VehicleMasterId }) =>
      encodeURIComponent(
        `${_startCase(Brand)}-${Model}-${VehicleMasterId}`.replaceAll(" ", "-").toLowerCase(),
      ),
    )
    .join(",");
};

export const getVehicleName = ({ Brand, Model, Grade }: VehicleDetail) => {
  return [_startCase(Brand), _startCase(Model), Grade].join(" ");
};

export const getVehicleImage = (data: Pick<VehicleDetail, "ExternalImage">) => {
  return data.ExternalImage?.find((image) => image.Position === "Side Right" || image.Position === "Side Left");
};

export function getVehiclePrice<T extends VehicleDetail>(
  data: Pick<
    T,
    "PromotionPrice" | "PromotionPriceWithVAT" | "ResellingPrice" | "ResellingPriceWithVAT"
  >,
) {
  const resellingPrice = data.ResellingPrice;
  const resellingPriceWithVat = data.ResellingPriceWithVAT;
  const carPrice = data.PromotionPrice > 0 ? data.PromotionPrice : data.ResellingPrice;
  const carPriceWithVat =
    data.PromotionPriceWithVAT > 0 ? data.PromotionPriceWithVAT : data.ResellingPriceWithVAT;
  return { carPrice, carPriceWithVat, resellingPrice, resellingPriceWithVat };
}

export const getMileageAverage = ({ year, mileage }: { year: number; mileage: number }) => {
  const yearCount = dayjs().get("year") - year;
  return (mileage ?? 0) / yearCount;
};

export const transformTypeVehicle = (
  data: Pick<
    VehicleDetail,
    | "VehicleMasterId"
    | "Brand"
    | "Model"
    | "Grade"
    | "Transmission"
    | "EngineCapacity"
    | "PromotionPrice"
    | "ResellingPrice"
    | "PromotionPriceWithVAT"
    | "ResellingPriceWithVAT"
    | "Year"
    | "Mileage"
    | "ExternalImage"
  >,
): VehicleCard => {
  const {
    VehicleMasterId,
    Brand,
    Model,
    Grade,
    Transmission: Gear,
    EngineCapacity,
    PromotionPrice,
    ResellingPrice,
    PromotionPriceWithVAT,
    ResellingPriceWithVAT,
    Year,
    Mileage,
    ExternalImage,
  } = data;

  const VehicleImageURL = ExternalImage?.find((image) => image.Position === "Cover")?.FileURL || "";
  const AverageMileage = getMileageAverage({ mileage: Mileage, year: Year });

  return {
    VehicleMasterId,
    VehicleImageURL,
    Brand,
    Model,
    Grade,
    Gear,
    EngineCapacity: Number(EngineCapacity),
    PromotionPrice,
    ResellingPrice,
    PromotionPriceWithVAT,
    ResellingPriceWithVAT,
    Year,
    Mileage,
    AverageMileage,
  } as VehicleCard;
};
