import { Box, Dialog, DialogContent, DialogContentProps, DialogProps } from "@mui/material";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButtonLightWeightCustom from "../Button/IconButtonLightWeightCustom";
import color from "@/styles/color";

interface IModalCloseOutBoxCustomProps {
  name?: string;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  dialogProps?: Partial<DialogProps>;
  dialogContentProps?: Partial<DialogContentProps>;
  showCloseIcon?: boolean;
}

function ModalCloseOutBoxCustom({
  name = "common",
  open,
  onClose,
  children,
  dialogProps,
  dialogContentProps,
  showCloseIcon = true,
}: IModalCloseOutBoxCustomProps) {
  return (
    <Dialog
      aria-labelledby={`${name}_dialog_title`}
      aria-describedby={`${name}_dialog_description`}
      {...dialogProps}
      open={open}
      onClose={(_, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") return;
        onClose();
      }}
      // scroll="body"
      sx={{
        "& .MuiPaper-root": {
          bgcolor: "transparent",
          margin: "8px",
          boxShadow: "unset",
          width: { xs: "960px" },
          maxWidth: { xs: "960px" },
        },
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
        ...dialogProps?.sx,
      }}
    >
      <DialogContent
        sx={{
          padding: "0px",
          backgroundColor: "transparent",
          ...dialogContentProps?.sx,
        }}
      >
        <Box component={"div"} sx={{ position: "relative" }}>
          {showCloseIcon && (
            <IconButtonLightWeightCustom
              aria-label="close-modal"
              sx={{
                position: "absolute",
                top: "5px",
                right: "5px",
                zIndex: 100,
                width: "60px",
                height: "60px",
                background: color.redGradient,
                ":hover": { background: color.redGradient, opacity: 0.9 },
              }}
              onClick={onClose}
            >
              <CloseRoundedIcon
                sx={{
                  fontSize: "40px",
                  color: color.white,
                }}
              />
            </IconButtonLightWeightCustom>
          )}
          {children}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ModalCloseOutBoxCustom;
