"use client";
import color from "@/styles/color";
import { Box, BoxProps, SxProps, Theme, Typography } from "@mui/material";
import React from "react";

interface IChipCustomProps extends BoxProps {
  label?: string;
  icon?: JSX.Element;
  containerStyles?: SxProps<Theme>;
  labelStyles?: SxProps<Theme>;
}

function ChipCustom({
  label,
  icon,
  containerStyles = {},
  labelStyles = {},
  ...rest
}: IChipCustomProps) {
  return (
    <Box
      {...rest}
      component={"div"}
      sx={{
        width: "fit-content",
        height: "23px",
        display: "flex",
        padding: "2px 8px",
        gap: "5.5px",
        border: "1px solid #E1E1E1",
        alignItems: "center",
        borderRadius: "100px",
        ...containerStyles,
      }}
    >
      {icon}
      {label && (
        <Typography
          component={"span"}
          sx={{
            fontWeight: 400,
            color: color.gray400,
            fontSize: { xs: "10px", lg: "11px" },
            lineHeight: { xs: "15px", lg: "23px" },
            ...labelStyles,
          }}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
}

export default ChipCustom;
